<template>
    <div class="crop-management">
         <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <van-cell v-if="item.crop" class="" v-for="(item, index) in list" :key="index">
                    <div class="content-item"><span class="title">{{item.crop}}</span></div>
                    <div class="content-item">
                        <img :src="item.cropUrl"  v-if="item.cropUrl != ''"/>
                    </div>
                    <div class="content-item"><span>{{item.description}}</span></div> 
                </van-cell>
                <!-- <van-cell v-else> -->
                    <div class="no-data" v-else>
                        <div>
                            <img src="../../assets/images/icon_nodata.png"/>
                        </div>
                        <div>
                            <span class="no-data-text">暂无数据</span>
                        </div>
                    </div>
                <!-- </van-cell> -->
            </van-list>
         </van-pull-refresh>
    </div>
</template>

<script>
import { listPageMassif,listPageMassifWork} from '@/api/plotManagement';
export default {
    data() {
        return {
            refreshing: false,//是否刷新
            loading: false,//是否等待
            finished: false,//是否结束
            list: [],
            listQuery: {
                pageIndex: 1,
                pageSize: 500
            }
        }
    },
    mounted() {
        this.listPageMassif();
    },
    methods: {
        /** 刷新 */
        onRefresh() {

        },
        /** 加载 */
        onLoad() {
            this.loading = false;
            this.refreshing = false;
        },
        listPageMassif() {
            listPageMassif(this.listQuery).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.list = res.data || [];
                }
            })
        },

    },
    beforeRouteEnter(to, from, next) {
        if (to.params.massifId) {
            next(vm => {
                vm.listQuery.massifId = to.params.massifId;
            })
        } else {
            next(vm => {
                
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .crop-management {
        height: 100%;
        background-color: #fff;
        .van-cell {
            width: 358px;
            min-height: 600px;
            margin: 0 auto;
            margin-bottom: 10px;
            border-radius: 8px;
            .content-item {
                display: flex;
                /* span:nth-of-type(1) {
                    display: inline-block;
                    width: 70px;
                    margin-right: 4px;
                    text-align: right;
                } */
                img {
                    width: 337px;
                    height: 200px;
                    margin: 0 auto;
                    margin-bottom: 20px;
                    border-radius: 4px;
                }
                span {
                    display: inline-block;
                    margin-top: 16px;
                    text-indent: 2em;//距离左边2个字的间距
                    font-size: 14px;
                    letter-spacing: 2px;
                    color: #505050;
                }
                .title {
                    width: 100%;
                    padding-top: 10px;
                    margin-top: 0;
                    margin-bottom: 20px;
                    font-size: 20px;
                    text-align: left;
                    font-size: 18px;
                    text-indent: 0;
                }
            }
            .no-data {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }
        .van-cell:nth-of-type(1) {
            margin-top: 10px;
        }
    }
     
</style>